/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import get from "lodash/get";
import Helmet from "react-helmet";
import Header from "./header/header";
import Footer from "./footer/footer";
//import { withPreview } from "gatsby-source-prismic-graphql";
import "./layout.scss";
import "@scss/global.scss";

const layoutQuery = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
    allPrismicFooter {
      edges {
        node {
          data {
            address_information {
              text
            }
            items {
              title
              cta_action {
                link_type
              }
            }
          }
        }
      }
    }
    allPrismicHeader {
      edges {
        node {
          data {
            title
            items {
              title
              classes
              mobile_only
              cta_action {
                link_type
                uid
                type
                url
              }
            }
          }
        }
      }
    }
  }
`;
const Layout = ({ children }) => (
  <StaticQuery
    query={layoutQuery}
    render={(data) => {
      const headerData = get(
        data,
        "allPrismicHeader.edges[0].node.data.items",
        []
      );
      const footerData = get(data, "allPrismicFooter.edges[0].node.data");
      return (
        <>
          <Helmet>
            <title>Future of Cities: Leading in LA</title>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
          </Helmet>
          <Header
            siteTitle={data.site.siteMetadata.title}
            headerData={headerData}
          />
          <div className="page">
            <div className="page__inner">
              <main>{children}</main>
              <Footer
                siteTitle={data.site.siteMetadata.title}
                footerData={footerData}
              />
            </div>
          </div>
        </>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
