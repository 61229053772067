import { Link } from "gatsby";
import React from "react";
import { RichText } from "prismic-reactjs";
import { FaInstagram, FaFacebookSquare, FaTwitterSquare } from "react-icons/fa";
import "./footer.scss";

const Footer = ({ siteTitle, footerData }) => {
  return (
    <footer className="footer">
      <div className="footer__inner">
        ©{new Date().getFullYear()}, {siteTitle}
        <address>{RichText.asText(footerData.address_information)}</address>
        <ul className="footer__social">
          <h4>Follow Us:</h4>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/FutureCitiesLA"
            >
              <FaFacebookSquare />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/futureofcities/"
            >
              <FaInstagram />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://twitter.com/FutureOf_Cities"
            >
              <FaTwitterSquare />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
export default Footer;
