import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import get from "lodash/get";
import heroImg from "@images/hero.png";
import Classset from "react-classset";
import urlParse from "url-parse";
import "./header.scss";

const Header = ({ siteTitle, headerData }) => {
  let [menuOpen, toggleMenuState] = useState(false);
  let handleMenuButton = () => {
    toggleMenuState(menuOpen ? false : true);
  };
  let navigationClasses = Classset({
    header__navigation: true,
    "header__navigation--active": menuOpen,
  });
  return (
    <header className="header">
      <div className="header__inner clearfix">
        <Link to="/">
          <div className="header__logo-wrap">
            <img className="header__logo" src={heroImg} />
            <h2>Future of Cities: Los Angeles</h2>
          </div>
        </Link>
        <ul className={navigationClasses}>
          {headerData &&
            headerData.map(function (menu, index) {
              return <HeaderItem key={index} data={menu} />;
            })}
        </ul>
        <button className="header__menu" onClick={handleMenuButton}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>
    </header>
  );
};

const HeaderItem = ({ data }) => {
  var { title, cta_action, classes } = data;

  classes = classes == null ? "" : classes;
  if (get(cta_action, "link_type") === "Web") {
    let linkTo = get(cta_action, "url");
    return (
      <li className={classes}>
        <a target="_blank" href={linkTo}>
          {title}
        </a>
      </li>
    );
  } else if (cta_action && get(cta_action, "uid")) {
    let linkTo = get(cta_action, "uid").replace(/\/$/, "");
    if (typeof window != "undefined") {
      if (urlParse(window.location).pathname === `/${linkTo}`) {
        classes += " active";
      }
    }
    return (
      <li className={classes}>
        <Link to={`/${linkTo}`}>{title}</Link>
      </li>
    );
  } else {
    return (
      <li className={classes}>
        <Link to={"/"}>{title}</Link>
      </li>
    );
  }
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
